<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          {{ item.id ? "Editar" : "Cadastrar" }} modelo de câmera
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-card outlined>
          <v-form ref="formCameraModels">
            <v-card-text>
              <!-- linha do modelo e fabricante -->
              <v-row>
                <v-col>
                  <v-text-field
                    label="Modelo da câmera"
                    v-model="item.name"
                    :counter="255"
                    :rules="requiredField"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Fabricante"
                    v-model="item.manufacturer"
                    :counter="255"
                    :rules="requiredField"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- descrição -->
              <v-row>
                <v-col>
                  <v-card outlined>
                    <v-card-title>Descrição do Modelo</v-card-title>
                    <v-card-text>
                      <vue-editor
                        v-model="item.description"
                        class="white black--text"
                      ></vue-editor>
                      <div v-html="item.description"></div>
                    </v-card-text>
                  </v-card>
                  <!-- <v-textarea
                    v-model="item.description"
                    rows="3"
                    label="Descrição"
                    hint="Digite uma descrição se necessario"
                  ></v-textarea> -->
                </v-col>
              </v-row>
              <!-- card das configurações -->
              <v-row>
                <v-col>
                  <Config :item="item.config" />
                </v-col>
              </v-row>
              <!-- linha do ativar -->
              <v-row>
                <v-spacer></v-spacer>
                <v-checkbox
                  label="Modelo ativo"
                  class="mr-5"
                  v-model="item.enabled"
                ></v-checkbox>
              </v-row>
              <!-- linha dos botoes -->
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="success" text @click="save"> Salvar </v-btn>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import { VueEditor } from "vue2-editor";
import Config from "./Config.vue";
export default {
  name: "FormCameraModel",
  components: {
    Config,
    VueEditor,
  },
  props: {
    item: {
      type: Object,
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      requiredField,
    };
  },
  methods: {
    save() {
      if (!this.$refs.formCameraModels.validate()) {
        return;
      }
      this.$emit("save", this.item);
    },
    close() {
      this.$emit("input", false);
    },
  },
  watch: {
    value: function (val) {
      if (!val) {
        this.$refs.formCameraModels.resetValidation();
      }
    },
  },
};
</script>

<style>
</style>