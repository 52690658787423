<template>
  <v-card>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Ativo</th>
              <th class="text-left">Modelo</th>
              <th class="text-left">Fabricante</th>
              <th class="text-left">Funcionalidades</th>
              <th class="text-left">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.enabled ? "Sim" : "Não" }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.manufacturer }}</td>
              <td>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(cfg, cidx) in explodeConfig(item.config)"
                        :key="cidx"
                      >
                        <td>{{ cfg.key }}</td>
                        <td>{{ cfg.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
              <td>
                <ToolTipIcon
                  icon="mdi-pencil"
                  text="Editar"
                  top="true"
                  @click="$emit('openDialoag', item)"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ToolTipIcon from "../../../components/ToolTipIcon.vue";
export default {
  name: "TableCliente",
  components: {
    ToolTipIcon,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    explodeConfig(config) {
      let configArray = [];
      for (let key in config) {
        configArray.push({ key: key, value: config[key] });
      }
      return configArray;
    },
  },
};
</script>

<style>
</style>