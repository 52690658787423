<template>
  <div>
      <v-card>
        <v-card-title> Configurações </v-card-title>
        <v-card-text>
          <v-form ref="formConfig" v-model="valid" lazy-validation>
            <v-row>
            <v-col>
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Largura da Detecção"
                        v-model="item.detectionLimits.width"
                        hint="Largura da Detecção em pixels"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Altura da Detecção"
                        v-model="item.detectionLimits.height"
                        hint="Altura da Detecção em pixels"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Tempo de Reconexão"
                        v-model="item.reconectTimeoutSeconds"
                        hint="Tempo de Reconexão em SEGUNDOS"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Tempo para REENVIAR Eventos de Conexão"
                        v-model="item.eventsConectionRepeatMinutes"
                        hint="Tempo para REENVIAR Eventos de Conexão em MINUTOS"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                    :items="item.buttonsAvailable"
                    label="Botões Disponíveis"
                    v-model="item.buttons"
                    multiple
                    chips
                    deletable-chips
                  ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import {
  ipField,
  portField,
  portFieldNotRequired,
} from "../../../Utils/InputValidation";
export default {
  name: "DialogIp",
  props:{
    item: {
      type: Object,
      required: false,
      defaul: () => ({
          detectionLimits: { width: 512, height: 512 },
          reconectTimeoutSeconds: 15,
          eventsConectionRepeatMinutes: 10,
          buttonsAvailable: [
            'verCamera',
            'openDialoag',
            'openDialoagAgenda',
            'openCamera',
            'openDialogConfig',
            'openDialogDraw',
            'openDialogStart',
            'openDialogSnap',
            ],
        }),
    },
  },
  data: () => ({
    ip: {
      ip: "",
      port: "",
      vmsPort: "",
    },
    valid: true,
    ipRules: ipField,
    portRules: portField,
    portRulesNotRequired: portFieldNotRequired,
  }),
  methods: {
    close() {
      this.$emit("input", false);
    },
    addIP() {
      if (this.$refs.formConfig.validate()) {
        this.$emit("addIp", this.ip);
      }
    },
  },
};
</script>

<style scoped>
</style>