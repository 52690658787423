<template>
  <div>
    <v-card>
      <v-card-title>
        Modelos de Câmeras
        <v-spacer> </v-spacer>
        <v-btn text color="success" @click="openDialoag()">
          <v-icon>mdi-plus</v-icon>
          Adicionar Modelo
        </v-btn>
      </v-card-title>
      <v-card-text>
        <TableCameraModel
          :items="$store.getters.getCamerasTypes"
          @openDialoag="openDialoag"
        />
      </v-card-text>
      <FormCameraModel
        :item="cameraModel"
        v-model="dialogCameraModel"
        @save="save"
      />
    </v-card>
  </div>
</template>

<script>
import FormCameraModel from "./components/FormCameraModel.vue";
import TableCameraModel from "./components/TableCameraModel.vue";
export default {
  name: "CameraModel",
  components: {
    FormCameraModel,
    TableCameraModel,
  },
  data: () => ({
    defaultModel: {},
    cameraModel: {
      enabled: true,
      id: 0,
      config: {
        detectionLimits: { width: 512, height: 512 },
        reconectTimeoutSeconds: 15,
        eventsConectionRepeatMinutes: 10,
        buttonsAvailable: [
          "verCamera",
          "openDialoag",
          "openDialoagAgenda",
          "openCamera",
          "openDialogConfig",
          "openDialogDraw",
          "openDialogStart",
          "openDialogSnap",
        ],
        buttons:[],
      },
    },
    dialogCameraModel: false,
  }),
  methods: {
    openDialoag(model) {
      if (model) {
        this.cameraModel = model;
        if(!this.cameraModel.config.buttonsAvailable){
          this.cameraModel.config.buttonsAvailable = this.defaultModel.config.buttonsAvailable;
        }
      } else {
        this.cameraModel = JSON.parse(JSON.stringify(this.defaultModel));
      }
      this.dialogCameraModel = true;
    },
    save(camera) {
      camera.cb = () => {
        this.dialogCameraModel = false;
      };
      this.$store.dispatch("saveCameraModel", camera);
    },
  },
  mounted() {
    this.$store.dispatch("loadAllCamTypes");
    this.defaultModel = JSON.parse(JSON.stringify(this.cameraModel));
  },
};
</script>

<style>
</style>